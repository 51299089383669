<template>
  <v-container fluid fill-height>
    <v-row class="mr-7 ma-0 pa-0">
      <v-col lg="7" cols="12" class="mr-12">
        <v-row no-gutters class="d-flex flex-column pa-0 ma-0">
          <v-row v-if="!isEvaluator" justify="end" class="ma-0 pa-0 pb-5">
            <bz-button
              :to="{
                name: 'add-course',
                query: {
                  course: trainingName,
                  trainingId: trainingId,
                },
              }"
              depressed
              icon="mdi-plus"
              width="170"
              title="Add Course"
              type="Submit"
              class="flat d-flex justify-self-end align-self-end"
            ></bz-button>
          </v-row>
          <v-card :loading="cardLoading" height="450" flat class="card-shadow">
            <v-card-title class="secondaryText--text body-1" primary-title
              >Syllabus</v-card-title
            >
            <v-divider></v-divider>
            <v-row no-gutters justify="center">
              <v-col lg="11" cols="11" sm="11">
                <v-card
                  color="transparent"
                  class="d-flex align-center justify-center mx-auto"
                  flat
                  height="300"
                  v-if="courses.length == 0"
                >
                  <v-card-text class="d-flex justify-center body-1"
                    >No courses to preview</v-card-text
                  >
                </v-card>
                <v-card
                  class="ma-2"
                  v-for="(course, index) in courses"
                  :key="index"
                  @click="navigate(course)"
                >
                  <v-row no-gutters class="d-flex align-center">
                    <v-col
                      lg="8"
                      md="8"
                      sm="6"
                      cols="12"
                      class="d-flex flex-column pl-6"
                      >{{ course.name }}
                    </v-col>

                    <v-col
                      v-if="isEvaluator && course.level !== null"
                      lg="4"
                      md="4"
                      cols="12"
                      sm="6"
                      class="ma-0 secondaryText--text d-flex justify-center"
                    >
                      {{ course.level }}</v-col
                    >
                    <v-col
                      v-if="!isEvaluator"
                      lg="4"
                      md="4"
                      cols="12"
                      sm="6"
                      class="ma-0 pa-0"
                    >
                      <v-col v-if="course.isComplete" class="ma-0 pa-2">
                        <bz-active-chip
                          class="primary caption textLight--text ma-0 pa-0"
                          active="ACTIVE"
                        />
                      </v-col>
                      <v-col v-else class="ma-0 pa-2">
                        <bz-active-chip
                          class="textOrange caption textLight--text ma-0 pa-0"
                          active="NOT ACTIVE"
                        />
                      </v-col>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
      <v-col class="mt-12">
        <div v-if="checkStatus() && !isEvaluator">
          <bz-share-card class="card-shadow" :code="code" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BzActiveChip from "@/beez-admin/components/BzActiveChip";
import BzButton from "@shared/components/BzSubmitButton";
import BzShareCard from "../../../../components/BzShareCard";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Courses",
  data() {
    return {
      code: "",
      courses: [],
      course: "",
      courseData: null,
      cardLoading: false,
    };
  },
  props: ["trainingName", "trainingId", "courseIds", "isEvaluator"],
  async created() {
    if (!this.isEvaluator) {
      this.cardLoading = true;
      const training = await this.getTraining(this.trainingId);
      // const coursesArray = await this.getCourses(this.trainingId);

      for (var i in training.courses) {
        // console.log(course.data[i]);
        this.courses.push(training.courses[i]);
      }
      //     const course = await this.getCourse({});
      //     this.courseData = course.data;
      //     if (this.courseData.length > 0) {
      //       this.courseData.map(element => {
      //         this.courses.push({
      //           id: element._id,
      //           name: element.name,
      //           description: element.description,
      //           level: element.level
      //         });
      //       });

      // let allAccounts = await this.getAllAccounts();
      // // this. getAvailableCourses();
      this.cardLoading = false;

      if (training.beezInCode) {
        let { beezInCode } = training;

        if (beezInCode !== null) {
          this.code = beezInCode;
        }
      }
    } else {
      if (this.evaluatorTrainings.length > 0) {
        this.setEvaluatorCourses();
      } else {
        this.cardLoading = true;
      }
    }
  },
  components: {
    BzButton,
    BzActiveChip,
    BzShareCard,
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("evaluator", ["evaluatorTrainings"]),
  },
  methods: {
    ...mapActions("admin", {
      getAllAccounts: "getAllAccounts",
    }),
    ...mapActions("training", {
      getCourse: "getCourse",
      getTraining: "getTraining",
      getCourses: "getCourses",
    }),
    ...mapActions("evaluator", {
      storeCourseId: "storeCourseId",
    }),
    navigate(course) {
      if (!this.isEvaluator) {
        this.$router.push({
          name: "add-course",
          query: {
            course: this.trainingName,
            trainingId: this.trainingId,
            courseId: course._id,
          },
        });
      } else {
        this.storeCourseId({
          courseId: course._id,
        });
        this.$router.push({
          name: "evaluator-trainees-list",
          query: {
            c: course.name,
            ci: course._id,
            t: this.trainingName,
            ti: this.trainingId,
          },
        });
      }
    },
    checkStatus() {
      if (this.code) {
        return true;
      } else {
        return false;
      }
    },
    setEvaluatorCourses() {
      this.courses = [];
      if (this.evaluatorTrainings.length > 0) {
        for (var j in this.evaluatorTrainings) {
          if (this.evaluatorTrainings[j]._id == this.trainingId) {
            for (var i in this.evaluatorTrainings[j].courses) {
              if (
                this.currentUser.courses.includes(
                  this.evaluatorTrainings[j].courses[i]._id
                )
              ) {
                this.courses.push(this.evaluatorTrainings[j].courses[i]);
              }
            }
            break;
          }
        }
      }
    },
    // async getAvailableCourses() {
    //   try {
    //     // const trainings = await this.fetchAvailableTrainings({});
    //     const course = await this.getCourse({});
    //     this.courseData = course.data;
    //     if (this.courseData.length > 0) {
    //       this.courseData.map(element => {
    //         this.courses.push({
    //           id: element._id,
    //           name: element.name,
    //           description: element.description,
    //           level: element.level
    //         });
    //       });
    //     }
    //   } catch (error) {
    //     // alert(error.message + "\t Failed to fetch Training card !");
    //   }
    // }
  },
  watch: {
    evaluatorTrainings: {
      handler() {
        this.cardLoading = false;
        this.setEvaluatorCourses();
      },
    },
    // courseIds: {
    //   async handler(value) {
    //     // console.log("Changed", value);
    //     let course = null;
    //     for (var index in value) {
    //       try {
    //         course = await this.getCourse({
    //           courseId: value[index],
    //           trainingId: this.trainingId
    //         });
    //       } catch (error) {
    //         console.log(error);
    //       }
    //       this.courses.push(course);
    //     }
    //     // console.log(this.courses);
    //   }
    // }
  },
};
</script>
