<template>
  <div>
    <admin-header :title="headerTitle" :links="bcLinks"></admin-header>
    <!-- <v-btn :to="{name: 'add-course', query: {
      course: headerTitle
    }}">Add Course</v-btn>-->
    <courses
      :trainingName="headerTitle"
      :trainingId="trainingId"
      :courseIds="courseIds"
      :isEvaluator="isEvaluator"
    />
  </div>
</template>

<script>
import Courses from "./Courses";
import { mapActions } from "vuex";
import AdminHeader from "@/beez-admin/components/AdminHeader";

export default {
  name: "CourseHome",
  data() {
    return {
      trainingId: null,
      headerTitle: ".",
      courseIds: [],
      isEvaluator: false
    };
  },
  components: {
    Courses,
    AdminHeader
  },
  async created() {
    this.headerTitle = this.$route.query.course;
    this.trainingId = this.$route.query.id;
    if (this.$router.currentRoute.name == "evaluator-course-home") {
      this.isEvaluator = true;
    } else {
      const training = await this.getTraining({ trainingId: this.trainingId });
      this.courseIds = training.courseIds;
    }
    // this.headerTitle = this.$route.params.courseName;

    // console.log("Here it is", a);
  },
  methods: {
    ...mapActions("training", {
      getTraining: "getTraining"
    })
  },
  computed: {
    bcLinks() {
      if (!this.isEvaluator) {
        return [
          {
            text: "Modules",
            disabled: false,
            to: {
              name: "modules"
            }
          },
          {
            text: "Elevation Module",
            disabled: false,
            to: {
              name: "elevation-module"
            }
          },
          {
            text: "Training",
            to: {
              name: "elevation-training-card"
            },
            disabled: false
          },
          {
            text: this.headerTitle,
            disabled: true
          }
        ];
      } else if (this.isEvaluator) {
        return [
          {
            text: "Elevation Module",
            disabled: true,
            to: {
              name: "elevation-module"
            }
          },
          {
            text: "Training",
            to: {
              name: "evaluator-training"
            },
            disabled: false
          },
          {
            text: this.headerTitle,
            disabled: true
          }
        ];
      }
    }
  }
};
</script>
